import React from 'react'

import AuthService from 'service/auth/AuthService'

import { Grid, Form, Input, Button } from 'semantic-ui-react'
import Terms from 'components/Signup/terms'
import Toast from 'components/common/Toast';


interface Props {
}

interface State {
    username: string
    email: string
    password: string
    token: string
}


class Signup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            username: "",
            email: "",
            password: "",
            token: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        // URL から Manager のキーを取得
        const paths = document.location.pathname.split("/")
        if (paths.length > 2 && paths[2] !== "") {
            this.setState({
                "token": paths[2]
            }, () => console.log(paths[2]));
        }
        else {
            console.log("test")
        }
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        const newState = { [event.target.name]:  event.target.value } as Pick<State, keyof State>;
        this.setState(newState);
    }

    handleSubmit(event){
        event.preventDefault();
        AuthService.signUp(this.state.username, this.state.email, this.state.password)
        .then((res) =>{
            document.location.href = '/'
            return res
        })
        .catch((e) => {
            Toast.error("サインアップに失敗しました。")
            throw e
        })
    }

    render() {
        return (
            <Grid>
                <Grid.Column textAlign="center">
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>ユーザID</label>
                            <Input
                                value={this.state.username}
                                onChange={this.handleInputChange}
                                name="username"
                                type="text"
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>メールアドレス</label>
                            <Input
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                name="email"
                                type="text"
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>パスワード</label>
                            <Input
                                value={this.state.password}
                                onChange={this.handleInputChange}
                                name="password"
                                type="password"
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                ユーザ登録いただいた時点で、下記規約に同意したものとみなします。
                <Terms/>
                <Button secondary onClick={this.handleSubmit}>サインアップ</Button>
                </Grid.Column>
            </Grid>
        )
    }
}

export default Signup;