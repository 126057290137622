import React from 'react'
import PostService, { Post } from 'service/api/PostService' 
import StripeService from 'service/stripe/StripeService'

import { Container, Header } from 'semantic-ui-react'


interface PostListProps {
}

interface PostListState {
    isLoading: boolean,
    posts: Post[]
}


class PostList extends React.Component<PostListProps, PostListState> {

    constructor(props: PostListProps) {
        super(props);
        this.state = {
            isLoading: true,
            posts: []
        };
        this.loadPosts = this.loadPosts.bind(this)
    }

    componentDidMount(){
        this.loadPosts(true)
    }

    async loadPosts(isInitial:boolean) {
        this.setState({ isLoading: isInitial });
        const res = await StripeService.checkHasSubscription()
        if ( res.data['has_subscription'] ) {
            PostService.getPosts()
                .then((values) => {
                    const posts = values.data.map((p: Post, i) => {
                        p['idx'] = i + 1;
                        return p;
                    })
                    this.setState({
                        posts,
                        isLoading: false
                    })
                }).catch(error => {
                    console.log(error)
                })
        } else {
            document.location.href = '/checkout'
        }
    }

    render() {
        return (
            <Container text style={{padding: "10px 0px",}} >
            {this.state.posts.map(item => (
                <div key={item.id}>
                    <Header as='h2'>{item.title}</Header>
                    <p>記事投稿日：{item.pub_date}</p>
                    <p>{item.content.split('\n').map((str, index) => (
                    <React.Fragment key={index}>{str}<br /></React.Fragment>
                    ))}</p>
                </div>
            ))}

            </Container>
        )
    }
}

export default PostList;