import axios from 'axios'
import { CardElement } from '@stripe/react-stripe-js'

import ConsoleAPIClient from 'service/utils/ConsoleAPIClient'

import { ConsoleAPIBaseURL } from 'config/BaseConfig'

export default class StripeService {
  static baseURL = ConsoleAPIBaseURL
  static headers = {
    Authorization: 'JWT ' + localStorage.getItem('access_token'),
    'Content-type': 'application/json',
  }

  static async createPaymentMethod(stripe, elements) {
    const cardElement = elements.getElement(CardElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      console.log('[error]', error)
    } else {
      console.log('[PaymentMethod]', paymentMethod)
      return paymentMethod
    }
  }

  static async createSubscription(paymentMethodId, priceId) {
    try {
      const res = await axios.post(
        this.baseURL + 'account/api/v1/create-subscription/',
        {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        },
        { headers: this.headers },
      )
      return res
    } catch (e) {
      document.location.href = '/'
      throw e
    }
  }

  static async checkHasSubscription() {
    return await ConsoleAPIClient.getWithAuth(
      '/account/api/v1/check-has-subscription/',
    )
  }
}
