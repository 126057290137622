import { loadStripe } from "@stripe/stripe-js";

const env = process.env.REACT_APP_BUILD_ENV
let prefix = 'REACT_APP_DEVELOPMENT_'
switch (env) {
  case 'production':
    prefix = 'REACT_APP_PRODUCTION_'
    break
  case 'staging':
    prefix = 'REACT_APP_STAGING_'
    break
}


export const ConsoleAPIBaseURL = process.env[prefix + 'CONSOLE_BASE_API_URL'] as string
export const StripePublicKey = process.env[prefix + 'STRIPE_PUBLIC_KEY'] as string
export const StripePriceId = process.env[prefix + 'STRIPE_PRICE_ID'] as string
export const stripePromise = loadStripe("pk_live_TUJhyfUoRflhDFGjxPn64S6z004VghbkmN");