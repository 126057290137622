import React from 'react'

import { Segment, Header } from 'semantic-ui-react'

class Terms extends React.Component {
    render() {
        return (
            <Segment style={{overflow: 'auto', maxHeight: 300 }}>
            <Header as='h3'>利用規約</Header>
<br />この利用規約（以下，「本規約」といいます。）は，ZOA（以下，「サービス提供者」といいます。）が「ZOAオンラインサロン」サービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。<br />
<br />第１条（本サービスについて）<br />
本サービスは、各種SNSを通じて、情報を発信するオーナーと会員、又は会員同士において情報又は意見交換等のコミュニケーションを行うことができる会員制のクローズドコミュニケーションサービスです。<br />
<br />第２条（適応）<br />
<br />
本規約は，ユーザーとサービス提供者との間における、本サービスの利用に関わる一切の関係に適用されるものとします。<br />
<br />第３条（利用登録）<br />
<br />登録希望者がサービス提供者の定める方法によって利用登録を申請し，サービス提供者がこれを承認することによって，利用登録が完了するものとします。サービス提供者は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。<br />
（1）利用登録の申請に際して虚偽の事項を届け出た場合<br />
（2）本規約に違反したことがある者からの申請である場合<br />
（3）その他，サービス提供者が利用登録を相当でないと判断した場合<br />
<br />
第４条（利用料金および支払方法）<br />
<br />
ユーザーは，本サービス利用の対価として，サービス提供者が別途定め，本ウェブサイトに表示する利用料金を，サービス提供者が指定する方法（クレジット決済、銀行振込）により支払うものとします。クレジット決済の場合、ユーザーのPaypalアカウントから初回月額費の支払いが行われた時点で入会となります。このとき、入会日の1ヶ月後が、次回の請求日となります。基本的に、毎月同じ日に請求されますが、入会日が29、30、31日のいずれかの場合は、翌月1日になるケースがあります。<br />
<br />
第５条（禁止事項）<br />
<br />
ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。<br />
（1）法令または公序良俗に違反する行為<br />
（2）犯罪行為に関連する行為<br />
（3）サービス提供者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為<br />
（4）サービス提供者のサービスの運営を妨害するおそれのある行為<br />
（5）他のユーザーに関する個人情報等を収集または蓄積する行為<br />
（6）他のユーザーに成りすます行為<br />
（7）サービス提供者のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為<br />
（8）その他，サービス提供者が不適切と判断する行為<br />
<br />
第６条（本サービスの提供の停止等）<br />
<br />
サービス提供者は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。<br />
（1）本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<br />
（2）地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合<br />
（3）コンピュータまたは通信回線等が事故により停止した場合<br />
（4）その他，サービス提供者が本サービスの提供が困難と判断した場合<br />
サービス提供者は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について，理由を問わず一切の責任を負わないものとします。<br />
<br />
第７条（利用制限および登録抹消）<br />
<br />
サービス提供者は，以下の場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。<br />
（1）本規約のいずれかの条項に違反した場合<br />
（2）登録事項に虚偽の事実があることが判明した場合<br />
（3）その他，サービス提供者が本サービスの利用を適当でないと判断した場合<br />
サービス提供者は，本条に基づきサービス提供者が行った行為によりユーザーに生じた損害について，一切の責任を負いません。<br />
<br />
第８条（免責事項）<br />
<br />
サービス提供者の債務不履行責任は，サービス提供者の故意または重過失によらない場合には免責されるものとします。<br />
サービス提供者は，何らかの理由によって責任を負う場合にも，通常生じうる損害の範囲内かつ有料サービスにおいては代金額（継続的サービスの場合には1か月分相当額）の範囲内においてのみ賠償の責任を負うものとします。<br />
サービス提供者は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。<br />
<br />
第９条（サービス内容の変更等）<br />
<br />
サービス提供者は，ユーザーに通知することなく，本サービスの内容を変更または本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。<br />
<br />
第１０条（利用者による利用契約の解除）<br />
<br />
ユーザーによる途中解約の際、解約月の支払済み料金等の返金はないものとします。<br />
ユーザーからの退会・解約は、&nbsp;本サービスのウェブサイト上の所定のページでのみ受け付けております。<br />
なお、解約・退会申請は、次回決済日の3営業日前までに行なってください。<br />
次回決済日の3営業日前を過ぎてからの解約・退会の申請は、次回決済後の受付となります。<br />
<br />
第１１条（利用規約の変更）<br />
<br />
サービス提供者は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。<br />
<br />
第１２条（通知または連絡）<br />
<br />
ユーザーとサービス提供者との間の通知または連絡は，サービス提供者の定める方法によって行うものとします。<br />
<br />
第１３条（権利義務の譲渡の禁止）<br />
<br />
ユーザーは，サービス提供者の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。<br />
<br />
第１４条（準拠法・裁判管轄）<br />
<br />
本規約の解釈にあたっては，日本法を準拠法とします。本サービスに関して紛争が生じた場合には，サービス提供者の本店所在地を管轄する裁判所を専属的合意管轄とします。
            </Segment>
        )
    }
}

export default Terms;