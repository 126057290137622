import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Header, Button, Icon } from 'semantic-ui-react'


class LP extends React.Component {
    render() {
        return (
            <Grid>
                <Grid.Column textAlign="center">
                    <Header as='h1'>ZOAサロン</Header>
                    <div>
                        <Button primary as={Link} to="/posts">
                            <Icon name="list" />
                            投稿一覧</Button>
                    </div>
                    <br/>
                    <div>
                        <Button primary as={Link} to="/signup">
                            <Icon name="signup" />
                            サインアップ</Button>
                    </div>
                    <br/>
                    <Button secondary as={Link} to="/login">
                            <Icon name="sign in" />
                        ログイン</Button>
                </Grid.Column>
            </Grid>
        )
    }
}

export default LP;