import ConsoleAPIClient from 'service/utils/ConsoleAPIClient'
import { AxiosResponse } from 'axios'

export interface Post {
    id: string
    title: string
    content: string
    pub_date: string
    idx?: number
}

interface PostsResponse {
    id: string
    title: string
    content: string
    pub_date: string
}


export default class PostService {

    static getPosts(): Promise<AxiosResponse<PostsResponse[]>>{
        return ConsoleAPIClient.getWithAuth("/salon/api/v1/post/")
    }
    
}