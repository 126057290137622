import React from 'react'
import { StripePriceId } from 'config/BaseConfig'

import { Grid, Button } from 'semantic-ui-react'

import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';

import StripeService from 'service/stripe/StripeService'


interface Props {
    stripe: any
    elements: any
}

interface State {
}


class CheckoutForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        const newState = { [event.target.name]:  event.target.value } as Pick<State, keyof State>;
        this.setState(newState);
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const {stripe, elements} = this.props;
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        const paymentMethod = await StripeService.createPaymentMethod(stripe, elements)
        await StripeService.createSubscription(paymentMethod.id, StripePriceId)
        document.location.href = '/login'
    }

    render() {
        return (
            <Grid>
                <Grid.Column textAlign="center">
                <CardElement />
                <Button secondary disabled={!this.props.stripe} onClick={this.handleSubmit}>チェックアウト</Button>
                </Grid.Column>
            </Grid>
        )
    }
}

const Checkout = () => {
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
            <CheckoutForm elements={elements} stripe={stripe} />
            )}
        </ElementsConsumer>
        );
    };

export default Checkout;