import React from 'react';

import { Elements } from "@stripe/react-stripe-js";
import { Route, Switch } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'

import { Container } from 'semantic-ui-react'

import { stripePromise } from 'config/BaseConfig'

import Header from 'components/Header'
import LP from 'components/LP'
import PostList from 'components/PostList'
import Login from 'components/Login'
import Signup from 'components/Signup'
import Checkout from 'components/Checkout'
import Error404 from 'components/Error404';


function App() {
  return (
    <div id="main_content" style={{ "top": "50px", "position": "absolute", "width": "100%" }}>
      <BrowserRouter basename="/">
        <Elements stripe={stripePromise}>
          <Header />
          <Container style={{ paddingTop: "20px"}}>
            <Switch>
              <Route path="/posts" component={PostList} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/signup/:token" component={Signup} />
              <Route path="/checkout" component={Checkout} />
              <Route path="/error" component={Error404} />
              <Route path="*" component={LP} />
            </Switch>            
          </Container>
        </Elements>
      </BrowserRouter>
    </div>
  );
}

export default App;
