import axios from 'axios';
import URLService from 'service/utils/URLService';
import AuthService from 'service/auth/AuthService'

import { ConsoleAPIBaseURL } from 'config/BaseConfig'

export default class ConsoleAPIClient {
  
  static baseURL = ConsoleAPIBaseURL

  static async getWithAuth(path) {
    try {
      await AuthService.refreshToken(localStorage.getItem('access_token'), localStorage.getItem('refresh_token'))
      const headers= {
        'Authorization': "JWT " + localStorage.getItem('access_token'),
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
      return await axios.get(
        URLService.joinURL(this.baseURL, path), { headers }
      )
    } catch (e) {
      document.location.href = '/login'
      throw e
    }
  }

  static async postWithAuth(path, data) {
    try {
      await AuthService.refreshToken(localStorage.getItem('access_token'), localStorage.getItem('refresh_token'))
      const headers= {
        'Authorization': "JWT " + localStorage.getItem('access_token'),
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
      return await axios.post(
        URLService.joinURL(this.baseURL, path), data, { headers }
      )
    } catch (e) {
      document.location.href = '/login'
      throw e
    }
  }

  static async getWithoutAuth(path) {
    try {
      const headers= {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
      return await axios.get(
        URLService.joinURL(this.baseURL, path), { headers }
      )
    } catch (e) {
      throw e
    }
  }

}