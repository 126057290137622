import React from 'react'
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import zoa_logo from '../images/zoa-logo-white.png'


interface HeaderProps {
    
}

interface HeaderState {
    isValidated: boolean
}

class Header extends React.Component<HeaderProps, HeaderState> {

    render() {
        return (
            <Menu inverted fixed="top" style={{ "background": "#1F323F", "userSelect": "none" }}>
                <Menu.Item style={{ "paddingTop": "12px", "paddingBottom": "6px", "paddingLeft": "20px", "paddingRight": "20px" }}>
                    <Link to='/'><img src={zoa_logo} alt={"zoa-logo"} height="28px" style={{ "padding": "2px" }} /></Link>
                </Menu.Item>
            </Menu>
        )
    }

}

export default Header;