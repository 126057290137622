import React from 'react';
import { Grid, Form, Input, Button } from 'semantic-ui-react'

import AuthService from 'service/auth/AuthService'

import Toast from 'components/common/Toast';


interface Props {
}

interface State {
    username: string
    password: string
}

class Login extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        const newState = { [event.target.name]:  event.target.value } as Pick<State, keyof State>;
        this.setState(newState);
    }

    handleSubmit(event){
        event.preventDefault();
        AuthService.signIn(this.state.username, this.state.password)
        .then((res) =>{
            document.location.href = '/posts'
            return res
        })
        .catch((e) => {
            Toast.error("ログインに失敗しました。")
            throw e
        })
    }

    render() {
        return (
            <Grid>
                <Grid.Column textAlign="center">
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>ユーザID</label>
                            <Input
                                value={this.state.username}
                                onChange={this.handleInputChange}
                                name="username"
                                type="text"
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>パスワード</label>
                            <Input
                                value={this.state.password}
                                onChange={this.handleInputChange}
                                name="password"
                                type="password"
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Button secondary onClick={this.handleSubmit}>ログイン</Button>
                </Grid.Column>
            </Grid>
        )
    }
}

export default Login;